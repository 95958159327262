 
import { mapGetters } from 'vuex';

export default {
  name: 'AppRating',
  props: {
    rating: {
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    affiliate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getFrontendSettings',
    }),
    affcolor() {
      return this.settings?.affiliate_color || '#000';
    },
    ratingStyle() {
      return {
        backgroundColor: this.affiliate ? this.affcolor : 'var(--app-rating)',
      };
    },
  },
};
