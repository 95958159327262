import recommendations from './recommendations.json';
import _ from 'lodash';

class Helpers {
  constructor(app) {
    this.app = app;
  }

  getDays = () => {
    let start = this.app.$moment.startOf('isoWeek');

    let days = [];
    for (let i = 0; i < 7; i++) {
      days.push({
        name: start.format('dddd'),
        short: start.format('dd'),
        index: start.day(),
      });
      start.add(1, 'day');
    }

    return days;
  };

  getRecommendations = () => {
    const recommendationsArray = recommendations[this.app.i18n.locale];

    if (recommendationsArray === undefined) {
      return [];
    }

    return recommendationsArray;
  };

  formatSchemaPrice = (price) => {
    return new Intl.NumberFormat('en').format(price);
  };

  maisonsToJsonLd = (maisons) => {
    const productSchemas = [];

    if (maisons && maisons.length) {
      maisons.forEach((maison) => {
        productSchemas.push({
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": maison.name,
            "image": `${process.env.API_URL}${maison.featured_image}`,
            "description": maison.description,
            "brand": {
              "@type": "Brand",
              "name": maison.name
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": maison.ratings_average,
              "reviewCount": maison.ratings_count
            },
            "offers": {
              "@type": "Offer",
              "price": maison.activity_starting_price,
              "priceCurrency": "EUR"
            }
          }
        });
      });
    }

    return productSchemas;
  };

  // Add this inside the Helpers class
  hotelsToJsonLd = (hotels) => {
    const hotelSchemas = [];

    if (hotels && hotels.length) {
      hotels.forEach((hotel) => {
        hotelSchemas.push({
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org/",
            "@type": "Hotel",
            "name": hotel.title,
            "image": `${process.env.API_URL}${hotel.featured_image}`,
            "description": hotel.description,
            // "address": {
            //   "@type": "PostalAddress",
            //   "streetAddress": hotel.location?.street_number ?? '',
            //   "addressLocality": hotel.location?.locality ?? '',
            //   "postalCode": hotel.location?.postal_code ?? '',
            //   "addressCountry": hotel.location?.country ?? '',
            // },
            // "geo": {
            //   "@type": "GeoCoordinates",
            //   "latitude": hotel.location?.latlng?.lat,
            //   "longitude": hotel.location?.latlng?.lng,
            // },
            "url": hotel.link,
            // "aggregateRating": {
            //   "@type": "AggregateRating",
            //   "ratingValue": hotel.ratings_average,
            //   "reviewCount": hotel.ratings_count,
            // },
          }
        });
      });
    }

    return hotelSchemas;
  };


  localBusinessJsonLd = (
    title = '',
    description = '',
    url = '',
    image = '',
    maisons,
  ) => {
    if (!maisons || !maisons.length) return null;

    if (!image) {
      image = `${process.env.APP_URL}/images/social-image.jpg`;
    }

    const prices = [];
    const ratings = [];
    let reviewCount = 0;

    maisons.forEach((maison) => {
      prices.push(maison.activity_max_price);
      prices.push(maison.activity_starting_price);

      ratings.push(maison.ratings_average);
      reviewCount += maison.ratings_count;
    });

    const minPrice = _.min(prices);
    const maxPrice = _.max(prices);
    const ratingValue = _.sum(ratings) / ratings.length;

    let priceRange = '';

    if (minPrice >= 0 && maxPrice >= 0) {
      priceRange = `€${this.formatSchemaPrice(minPrice)} - €${this.formatSchemaPrice(maxPrice)}`;
    }

    return {
      '@context': 'http://schema.org',
      '@type': 'LocalBusiness',
      url,
      name: title,
      image,
      priceRange,
      description,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue,
        reviewCount,
      },
    };
  };


}

export default Helpers;
