import { render, staticRenderFns } from "./Maisons.vue?vue&type=template&id=e2ce247c&scoped=true&"
import script from "./Maisons.vue?vue&type=script&lang=js&"
export * from "./Maisons.vue?vue&type=script&lang=js&"
import style0 from "./Maisons.vue?vue&type=style&index=0&id=e2ce247c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2ce247c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppCkContent: require('/home/forge/www.champagne-booking.com/components/AppCkContent.vue').default,MaisonSlider: require('/home/forge/www.champagne-booking.com/components/MaisonSlider.vue').default,AppLabel: require('/home/forge/www.champagne-booking.com/components/AppLabel.vue').default,MaisonCard: require('/home/forge/www.champagne-booking.com/components/MaisonCard.vue').default,MaisonCardSkeleton: require('/home/forge/www.champagne-booking.com/components/MaisonCardSkeleton.vue').default,BtnUnderline: require('/home/forge/www.champagne-booking.com/components/BtnUnderline.vue').default})
