
export default {
  props: {
    highlights: {
      type: Array,
      default: () => [],
    },
    getTranslatedText: {
      type: Function,
      required: true,
    },
  },
};
