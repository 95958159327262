
export default {
  name: 'AppPrice',
  props: {
    price: {
      default: 0,
      type: Number,
    },
    showDecimals: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    correctPrice() {
      let options = {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      if (!this.showDecimals) {
        options.minimumFractionDigits = 0;
        options.maximumFractionDigits = 0;
      }

      if(Number.isInteger(this.price)){
        options.minimumFractionDigits = 0;
        options.maximumFractionDigits = 0;
      }

      return new Intl.NumberFormat(this.currentLocale, options).format(this.price);
    },
  },
};
