import { render, staticRenderFns } from "./SearchMaisonsAndActivities.vue?vue&type=template&id=71f465e8&scoped=true&"
import script from "./SearchMaisonsAndActivities.vue?vue&type=script&lang=js&"
export * from "./SearchMaisonsAndActivities.vue?vue&type=script&lang=js&"
import style0 from "./SearchMaisonsAndActivities.vue?vue&type=style&index=0&id=71f465e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f465e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormulateGeoSearch: require('/home/forge/www.champagne-booking.com/components/FormulateGeoSearch.vue').default,AppDropdown: require('/home/forge/www.champagne-booking.com/components/AppDropdown.vue').default,FormulateQuantity: require('/home/forge/www.champagne-booking.com/components/FormulateQuantity.vue').default,SearchButton: require('/home/forge/www.champagne-booking.com/components/SearchButton.vue').default,SearchBar: require('/home/forge/www.champagne-booking.com/components/SearchBar.vue').default})
