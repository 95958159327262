export default function ({ $auth, app }) {
    $auth.onRedirect((to) => {
        if (to === 'account' && ($auth.user.is_admin || $auth.user.is_manager)) {
            to = 'dashboard';
        }

        return app.localePath({
            name: to,
        });
    });
}
