import { render, staticRenderFns } from "./MaisonCard.vue?vue&type=template&id=083827ae&scoped=true&"
import script from "./MaisonCard.vue?vue&type=script&lang=js&"
export * from "./MaisonCard.vue?vue&type=script&lang=js&"
import style0 from "./MaisonCard.vue?vue&type=style&index=0&id=083827ae&prod&lang=scss&scoped=true&"
import style1 from "./MaisonCard.vue?vue&type=style&index=1&id=083827ae&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "083827ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppFavoriteButton: require('/home/forge/www.champagne-booking.com/components/AppFavoriteButton.vue').default,AppThumbnailGallery: require('/home/forge/www.champagne-booking.com/components/AppThumbnailGallery.vue').default,AppRating: require('/home/forge/www.champagne-booking.com/components/AppRating.vue').default,AppStars: require('/home/forge/www.champagne-booking.com/components/AppStars.vue').default,AppPrice: require('/home/forge/www.champagne-booking.com/components/AppPrice.vue').default})
