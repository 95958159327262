
export default {
  name: 'HotelSlider',
  props: ['hotels'],
  data() {
    return {
      options: {
        threshold: 15,
        spaceBetween: 24,
        slidesPerView: 1.2,
        breakpoints: {
          376: {
            slidesPerView: 1.2,
          },
          768: {
            slidesPerView: 2.3,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      },
    };
  },
  computed: {
    isMobileLayout() {
      // sMd keeps the whole md breakpoint in mind to, so lower than lg.
      return this.$breakpoints.sMd;
    },
  },
};
