import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6b44a572 = () => interopDefault(import('../pages/signup-champagne-house.vue' /* webpackChunkName: "pages/signup-champagne-house" */))
const _31909aa0 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _2d81159a = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _9df7552e = () => interopDefault(import('../pages/account/bookings.vue' /* webpackChunkName: "pages/account/bookings" */))
const _218d781c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _64b6ebbc = () => interopDefault(import('../pages/maisons/index.vue' /* webpackChunkName: "pages/maisons/index" */))
const _93f1c9ba = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _412718d7 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _c2c53bcc = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _62c4168c = () => interopDefault(import('../pages/dashboard/accommodations/index.vue' /* webpackChunkName: "pages/dashboard/accommodations/index" */))
const _3bbc1025 = () => interopDefault(import('../pages/dashboard/bookings/index.vue' /* webpackChunkName: "pages/dashboard/bookings/index" */))
const _63cb5526 = () => interopDefault(import('../pages/dashboard/chats/index.vue' /* webpackChunkName: "pages/dashboard/chats/index" */))
const _a865a344 = () => interopDefault(import('../pages/dashboard/faq.vue' /* webpackChunkName: "pages/dashboard/faq" */))
const _2c906341 = () => interopDefault(import('../pages/dashboard/maisons/index.vue' /* webpackChunkName: "pages/dashboard/maisons/index" */))
const _66455371 = () => interopDefault(import('../pages/dashboard/profile.vue' /* webpackChunkName: "pages/dashboard/profile" */))
const _107775b4 = () => interopDefault(import('../pages/dashboard/reviews/index.vue' /* webpackChunkName: "pages/dashboard/reviews/index" */))
const _72f794ee = () => interopDefault(import('../pages/dashboard/accommodations/_accommodation.vue' /* webpackChunkName: "pages/dashboard/accommodations/_accommodation" */))
const _3e6a9d68 = () => interopDefault(import('../pages/dashboard/accommodations/_accommodation/index.vue' /* webpackChunkName: "pages/dashboard/accommodations/_accommodation/index" */))
const _0bfd0e34 = () => interopDefault(import('../pages/dashboard/accommodations/_accommodation/_room.vue' /* webpackChunkName: "pages/dashboard/accommodations/_accommodation/_room" */))
const _1ee9d7cd = () => interopDefault(import('../pages/dashboard/bookings/_id.vue' /* webpackChunkName: "pages/dashboard/bookings/_id" */))
const _58169090 = () => interopDefault(import('../pages/dashboard/bookings/_id/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index" */))
const _03606608 = () => interopDefault(import('../pages/dashboard/bookings/_id/propose.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/propose" */))
const _6542962a = () => interopDefault(import('../pages/dashboard/maisons/_maison.vue' /* webpackChunkName: "pages/dashboard/maisons/_maison" */))
const _53ac9f2e = () => interopDefault(import('../pages/dashboard/maisons/_maison/index.vue' /* webpackChunkName: "pages/dashboard/maisons/_maison/index" */))
const _3e2f6aca = () => interopDefault(import('../pages/dashboard/maisons/_maison/_activity.vue' /* webpackChunkName: "pages/dashboard/maisons/_maison/_activity" */))
const _52fc116a = () => interopDefault(import('../pages/dashboard/reviews/_review.vue' /* webpackChunkName: "pages/dashboard/reviews/_review" */))
const _3797ff1a = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _398fd00f = () => interopDefault(import('../pages/hotels/index.vue' /* webpackChunkName: "pages/hotels/index" */))
const _8b092a9c = () => interopDefault(import('../pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _5ce51db1 = () => interopDefault(import('../pages/accommodations/index.vue' /* webpackChunkName: "pages/accommodations/index" */))
const _66a0c6cb = () => interopDefault(import('../pages/preview.vue' /* webpackChunkName: "pages/preview" */))
const _9f4ee502 = () => interopDefault(import('../pages/proposal.vue' /* webpackChunkName: "pages/proposal" */))
const _1093c9e5 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _ed7e8cb0 = () => interopDefault(import('../pages/verification.vue' /* webpackChunkName: "pages/verification" */))
const _308e1388 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _30e18a0e = () => interopDefault(import('../pages/book/accommodation.vue' /* webpackChunkName: "pages/book/accommodation" */))
const _7177e2bc = () => interopDefault(import('../pages/book/accommodation/index.vue' /* webpackChunkName: "pages/book/accommodation/index" */))
const _ae50888a = () => interopDefault(import('../pages/book/accommodation/confirmation.vue' /* webpackChunkName: "pages/book/accommodation/confirmation" */))
const _358357b8 = () => interopDefault(import('../pages/book/activity.vue' /* webpackChunkName: "pages/book/activity" */))
const _1ead343b = () => interopDefault(import('../pages/book/activity/index.vue' /* webpackChunkName: "pages/book/activity/index" */))
const _6dea8d5c = () => interopDefault(import('../pages/book/activity/confirmation.vue' /* webpackChunkName: "pages/book/activity/confirmation" */))
const _64a3bd5e = () => interopDefault(import('../pages/book/confirmation.vue' /* webpackChunkName: "pages/book/confirmation" */))
const _29ee313a = () => interopDefault(import('../pages/blog/categories/_category.vue' /* webpackChunkName: "pages/blog/categories/_category" */))
const _758ddce3 = () => interopDefault(import('../pages/blog/posts/_post.vue' /* webpackChunkName: "pages/blog/posts/_post" */))
const _5819a6c0 = () => interopDefault(import('../pages/maisons/_region/index.vue' /* webpackChunkName: "pages/maisons/_region/index" */))
const _8ad8ad74 = () => interopDefault(import('../pages/chats/_chat.vue' /* webpackChunkName: "pages/chats/_chat" */))
const _75ce3512 = () => interopDefault(import('../pages/confirmation/_booking.vue' /* webpackChunkName: "pages/confirmation/_booking" */))
const _498082ca = () => interopDefault(import('../pages/hotels/_city/index.vue' /* webpackChunkName: "pages/hotels/_city/index" */))
const _1ab9834a = () => interopDefault(import('../pages/signin/_token.vue' /* webpackChunkName: "pages/signin/_token" */))
const _1b8f2ff8 = () => interopDefault(import('../pages/accommodations/_accommodation.vue' /* webpackChunkName: "pages/accommodations/_accommodation" */))
const _5d60f39a = () => interopDefault(import('../pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))
const _acfda458 = () => interopDefault(import('../pages/review/_token.vue' /* webpackChunkName: "pages/review/_token" */))
const _5d35f3ad = () => interopDefault(import('../pages/maisons/_region/_maison/index.vue' /* webpackChunkName: "pages/maisons/_region/_maison/index" */))
const _50fba135 = () => interopDefault(import('../pages/tour/_region/_maison/_activity.vue' /* webpackChunkName: "pages/tour/_region/_maison/_activity" */))
const _0886da31 = () => interopDefault(import('../pages/_page.vue' /* webpackChunkName: "pages/_page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aanmelden-als-champagnehuis",
    component: _6b44a572,
    name: "signup-champagne-house___nl"
  }, {
    path: "/account",
    component: _31909aa0,
    children: [{
      path: "/mijn-account",
      component: _2d81159a,
      name: "account___nl"
    }, {
      path: "/mijn-account/boekingen",
      component: _9df7552e,
      name: "account-bookings___nl"
    }]
  }, {
    path: "/blog",
    component: _218d781c,
    name: "blog___nl"
  }, {
    path: "/champagnehuizen",
    component: _64b6ebbc,
    name: "maisons___nl"
  }, {
    path: "/contact",
    component: _93f1c9ba,
    name: "contact___nl"
  }, {
    path: "/dashboard",
    component: _412718d7,
    children: [{
      path: "",
      component: _c2c53bcc,
      name: "dashboard___nl"
    }, {
      path: "accommodations",
      component: _62c4168c,
      name: "dashboard-accommodations___nl"
    }, {
      path: "bookings",
      component: _3bbc1025,
      name: "dashboard-bookings___nl"
    }, {
      path: "chats",
      component: _63cb5526,
      name: "dashboard-chats___nl"
    }, {
      path: "faq",
      component: _a865a344,
      name: "dashboard-faq___nl"
    }, {
      path: "maisons",
      component: _2c906341,
      name: "dashboard-maisons___nl"
    }, {
      path: "profile",
      component: _66455371,
      name: "dashboard-profile___nl"
    }, {
      path: "reviews",
      component: _107775b4,
      name: "dashboard-reviews___nl"
    }, {
      path: "accommodations/:accommodation",
      component: _72f794ee,
      children: [{
        path: "",
        component: _3e6a9d68,
        name: "dashboard-accommodations-accommodation___nl"
      }, {
        path: ":room",
        component: _0bfd0e34,
        name: "dashboard-accommodations-accommodation-room___nl"
      }]
    }, {
      path: "bookings/:id",
      component: _1ee9d7cd,
      children: [{
        path: "",
        component: _58169090,
        name: "dashboard-bookings-id___nl"
      }, {
        path: "propose",
        component: _03606608,
        name: "dashboard-bookings-id-propose___nl"
      }]
    }, {
      path: "maisons/:maison",
      component: _6542962a,
      children: [{
        path: "",
        component: _53ac9f2e,
        name: "dashboard-maisons-maison___nl"
      }, {
        path: ":activity",
        component: _3e2f6aca,
        name: "dashboard-maisons-maison-activity___nl"
      }]
    }, {
      path: "reviews/:review",
      component: _52fc116a,
      name: "dashboard-reviews-review___nl"
    }]
  }, {
    path: "/favorites",
    component: _3797ff1a,
    name: "favorites___nl"
  }, {
    path: "/hotels",
    component: _398fd00f,
    name: "hotels___nl"
  }, {
    path: "/inloggen",
    component: _8b092a9c,
    name: "signin___nl"
  }, {
    path: "/overnachtingen",
    component: _5ce51db1,
    name: "accommodations___nl"
  }, {
    path: "/preview",
    component: _66a0c6cb,
    name: "preview___nl"
  }, {
    path: "/proposal",
    component: _9f4ee502,
    name: "proposal___nl"
  }, {
    path: "/registreren",
    component: _1093c9e5,
    name: "signup___nl"
  }, {
    path: "/verification",
    component: _ed7e8cb0,
    name: "verification___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _308e1388,
    name: "forgot-password___nl"
  }, {
    path: "/book/accommodation",
    component: _30e18a0e,
    children: [{
      path: "",
      component: _7177e2bc,
      name: "book-accommodation___nl"
    }, {
      path: "confirmation",
      component: _ae50888a,
      name: "book-accommodation-confirmation___nl"
    }]
  }, {
    path: "/book/activity",
    component: _358357b8,
    children: [{
      path: "",
      component: _1ead343b,
      name: "book-activity___nl"
    }, {
      path: "confirmation",
      component: _6dea8d5c,
      name: "book-activity-confirmation___nl"
    }]
  }, {
    path: "/book/confirmation",
    component: _64a3bd5e,
    name: "book-confirmation___nl"
  }, {
    path: "/en/account",
    component: _31909aa0,
    children: [{
      path: "/en/my-account",
      component: _2d81159a,
      name: "account___en"
    }, {
      path: "/en/my-account/bookings",
      component: _9df7552e,
      name: "account-bookings___en"
    }]
  }, {
    path: "/en/blog",
    component: _218d781c,
    name: "blog___en"
  }, {
    path: "/en/champagne-houses",
    component: _64b6ebbc,
    name: "maisons___en"
  }, {
    path: "/en/contact",
    component: _93f1c9ba,
    name: "contact___en"
  }, {
    path: "/en/dashboard",
    component: _412718d7,
    children: [{
      path: "",
      component: _c2c53bcc,
      name: "dashboard___en"
    }, {
      path: "accommodations",
      component: _62c4168c,
      name: "dashboard-accommodations___en"
    }, {
      path: "bookings",
      component: _3bbc1025,
      name: "dashboard-bookings___en"
    }, {
      path: "chats",
      component: _63cb5526,
      name: "dashboard-chats___en"
    }, {
      path: "faq",
      component: _a865a344,
      name: "dashboard-faq___en"
    }, {
      path: "maisons",
      component: _2c906341,
      name: "dashboard-maisons___en"
    }, {
      path: "profile",
      component: _66455371,
      name: "dashboard-profile___en"
    }, {
      path: "reviews",
      component: _107775b4,
      name: "dashboard-reviews___en"
    }, {
      path: "accommodations/:accommodation",
      component: _72f794ee,
      children: [{
        path: "",
        component: _3e6a9d68,
        name: "dashboard-accommodations-accommodation___en"
      }, {
        path: ":room",
        component: _0bfd0e34,
        name: "dashboard-accommodations-accommodation-room___en"
      }]
    }, {
      path: "bookings/:id",
      component: _1ee9d7cd,
      children: [{
        path: "",
        component: _58169090,
        name: "dashboard-bookings-id___en"
      }, {
        path: "propose",
        component: _03606608,
        name: "dashboard-bookings-id-propose___en"
      }]
    }, {
      path: "maisons/:maison",
      component: _6542962a,
      children: [{
        path: "",
        component: _53ac9f2e,
        name: "dashboard-maisons-maison___en"
      }, {
        path: ":activity",
        component: _3e2f6aca,
        name: "dashboard-maisons-maison-activity___en"
      }]
    }, {
      path: "reviews/:review",
      component: _52fc116a,
      name: "dashboard-reviews-review___en"
    }]
  }, {
    path: "/en/favorites",
    component: _3797ff1a,
    name: "favorites___en"
  }, {
    path: "/en/forgot-password",
    component: _308e1388,
    name: "forgot-password___en"
  }, {
    path: "/en/hotels",
    component: _398fd00f,
    name: "hotels___en"
  }, {
    path: "/en/login",
    component: _8b092a9c,
    name: "signin___en"
  }, {
    path: "/en/overnight-stays",
    component: _5ce51db1,
    name: "accommodations___en"
  }, {
    path: "/en/preview",
    component: _66a0c6cb,
    name: "preview___en"
  }, {
    path: "/en/proposal",
    component: _9f4ee502,
    name: "proposal___en"
  }, {
    path: "/en/registration-for-new-champagne-houses",
    component: _6b44a572,
    name: "signup-champagne-house___en"
  }, {
    path: "/en/signup",
    component: _1093c9e5,
    name: "signup___en"
  }, {
    path: "/en/verification",
    component: _ed7e8cb0,
    name: "verification___en"
  }, {
    path: "/fr/account",
    component: _31909aa0,
    children: [{
      path: "/fr/mon-compte",
      component: _2d81159a,
      name: "account___fr"
    }, {
      path: "/fr/mon-compte/reservations",
      component: _9df7552e,
      name: "account-bookings___fr"
    }]
  }, {
    path: "/fr/blog",
    component: _218d781c,
    name: "blog___fr"
  }, {
    path: "/fr/connexion",
    component: _8b092a9c,
    name: "signin___fr"
  }, {
    path: "/fr/contact",
    component: _93f1c9ba,
    name: "contact___fr"
  }, {
    path: "/fr/dashboard",
    component: _412718d7,
    children: [{
      path: "",
      component: _c2c53bcc,
      name: "dashboard___fr"
    }, {
      path: "accommodations",
      component: _62c4168c,
      name: "dashboard-accommodations___fr"
    }, {
      path: "bookings",
      component: _3bbc1025,
      name: "dashboard-bookings___fr"
    }, {
      path: "chats",
      component: _63cb5526,
      name: "dashboard-chats___fr"
    }, {
      path: "faq",
      component: _a865a344,
      name: "dashboard-faq___fr"
    }, {
      path: "maisons",
      component: _2c906341,
      name: "dashboard-maisons___fr"
    }, {
      path: "profile",
      component: _66455371,
      name: "dashboard-profile___fr"
    }, {
      path: "reviews",
      component: _107775b4,
      name: "dashboard-reviews___fr"
    }, {
      path: "accommodations/:accommodation",
      component: _72f794ee,
      children: [{
        path: "",
        component: _3e6a9d68,
        name: "dashboard-accommodations-accommodation___fr"
      }, {
        path: ":room",
        component: _0bfd0e34,
        name: "dashboard-accommodations-accommodation-room___fr"
      }]
    }, {
      path: "bookings/:id",
      component: _1ee9d7cd,
      children: [{
        path: "",
        component: _58169090,
        name: "dashboard-bookings-id___fr"
      }, {
        path: "propose",
        component: _03606608,
        name: "dashboard-bookings-id-propose___fr"
      }]
    }, {
      path: "maisons/:maison",
      component: _6542962a,
      children: [{
        path: "",
        component: _53ac9f2e,
        name: "dashboard-maisons-maison___fr"
      }, {
        path: ":activity",
        component: _3e2f6aca,
        name: "dashboard-maisons-maison-activity___fr"
      }]
    }, {
      path: "reviews/:review",
      component: _52fc116a,
      name: "dashboard-reviews-review___fr"
    }]
  }, {
    path: "/fr/favorites",
    component: _3797ff1a,
    name: "favorites___fr"
  }, {
    path: "/fr/hotels",
    component: _398fd00f,
    name: "hotels___fr"
  }, {
    path: "/fr/inscription-nouvelles-maisons-de-champagne",
    component: _6b44a572,
    name: "signup-champagne-house___fr"
  }, {
    path: "/fr/maisons-de-champagne",
    component: _64b6ebbc,
    name: "maisons___fr"
  }, {
    path: "/fr/mot-de-passe-oublie",
    component: _308e1388,
    name: "forgot-password___fr"
  }, {
    path: "/fr/nuitees",
    component: _5ce51db1,
    name: "accommodations___fr"
  }, {
    path: "/fr/preview",
    component: _66a0c6cb,
    name: "preview___fr"
  }, {
    path: "/fr/proposal",
    component: _9f4ee502,
    name: "proposal___fr"
  }, {
    path: "/fr/sinscrire",
    component: _1093c9e5,
    name: "signup___fr"
  }, {
    path: "/fr/verification",
    component: _ed7e8cb0,
    name: "verification___fr"
  }, {
    path: "/en/book/accommodation",
    component: _30e18a0e,
    children: [{
      path: "",
      component: _7177e2bc,
      name: "book-accommodation___en"
    }, {
      path: "confirmation",
      component: _ae50888a,
      name: "book-accommodation-confirmation___en"
    }]
  }, {
    path: "/en/book/activity",
    component: _358357b8,
    children: [{
      path: "",
      component: _1ead343b,
      name: "book-activity___en"
    }, {
      path: "confirmation",
      component: _6dea8d5c,
      name: "book-activity-confirmation___en"
    }]
  }, {
    path: "/en/book/confirmation",
    component: _64a3bd5e,
    name: "book-confirmation___en"
  }, {
    path: "/fr/book/accommodation",
    component: _30e18a0e,
    children: [{
      path: "",
      component: _7177e2bc,
      name: "book-accommodation___fr"
    }, {
      path: "confirmation",
      component: _ae50888a,
      name: "book-accommodation-confirmation___fr"
    }]
  }, {
    path: "/fr/book/activity",
    component: _358357b8,
    children: [{
      path: "",
      component: _1ead343b,
      name: "book-activity___fr"
    }, {
      path: "confirmation",
      component: _6dea8d5c,
      name: "book-activity-confirmation___fr"
    }]
  }, {
    path: "/fr/book/confirmation",
    component: _64a3bd5e,
    name: "book-confirmation___fr"
  }, {
    path: "/en/blog/categories/:category?",
    component: _29ee313a,
    name: "blog-categories-category___en"
  }, {
    path: "/en/blog/posts/:post?",
    component: _758ddce3,
    name: "blog-posts-post___en"
  }, {
    path: "/fr/blog/categories/:category?",
    component: _29ee313a,
    name: "blog-categories-category___fr"
  }, {
    path: "/fr/blog/posts/:post?",
    component: _758ddce3,
    name: "blog-posts-post___fr"
  }, {
    path: "/blog/categories/:category?",
    component: _29ee313a,
    name: "blog-categories-category___nl"
  }, {
    path: "/blog/posts/:post?",
    component: _758ddce3,
    name: "blog-posts-post___nl"
  }, {
    path: "/en/champagne-houses/:region",
    component: _5819a6c0,
    name: "maisons-region___en"
  }, {
    path: "/en/chats/:chat?",
    component: _8ad8ad74,
    name: "chats-chat___en"
  }, {
    path: "/en/confirmation/:booking?",
    component: _75ce3512,
    name: "confirmation-booking___en"
  }, {
    path: "/en/hotels/:city",
    component: _498082ca,
    name: "hotels-city___en"
  }, {
    path: "/en/login/:token",
    component: _1ab9834a,
    name: "signin-token___en"
  }, {
    path: "/en/overnight-stays/:accommodation",
    component: _1b8f2ff8,
    name: "accommodations-accommodation___en"
  }, {
    path: "/en/reset-password/:token",
    component: _5d60f39a,
    name: "reset-password-token___en"
  }, {
    path: "/en/review/:token?",
    component: _acfda458,
    name: "review-token___en"
  }, {
    path: "/fr/chats/:chat?",
    component: _8ad8ad74,
    name: "chats-chat___fr"
  }, {
    path: "/fr/confirmation/:booking?",
    component: _75ce3512,
    name: "confirmation-booking___fr"
  }, {
    path: "/fr/connexion/:token",
    component: _1ab9834a,
    name: "signin-token___fr"
  }, {
    path: "/fr/hotels/:city",
    component: _498082ca,
    name: "hotels-city___fr"
  }, {
    path: "/fr/maisons-de-champagne/:region",
    component: _5819a6c0,
    name: "maisons-region___fr"
  }, {
    path: "/fr/nuitees/:accommodation",
    component: _1b8f2ff8,
    name: "accommodations-accommodation___fr"
  }, {
    path: "/fr/reinitialiser-le-mot-de-passe/:token",
    component: _5d60f39a,
    name: "reset-password-token___fr"
  }, {
    path: "/fr/review/:token?",
    component: _acfda458,
    name: "review-token___fr"
  }, {
    path: "/en/champagne-houses/:region/:maison",
    component: _5d35f3ad,
    name: "maisons-region-maison___en"
  }, {
    path: "/fr/maisons-de-champagne/:region/:maison",
    component: _5d35f3ad,
    name: "maisons-region-maison___fr"
  }, {
    path: "/en/tour/:region?/:maison?/:activity?",
    component: _50fba135,
    name: "tour-region-maison-activity___en"
  }, {
    path: "/fr/tour/:region?/:maison?/:activity?",
    component: _50fba135,
    name: "tour-region-maison-activity___fr"
  }, {
    path: "/champagnehuizen/:region",
    component: _5819a6c0,
    name: "maisons-region___nl"
  }, {
    path: "/chats/:chat?",
    component: _8ad8ad74,
    name: "chats-chat___nl"
  }, {
    path: "/confirmation/:booking?",
    component: _75ce3512,
    name: "confirmation-booking___nl"
  }, {
    path: "/en/:page?",
    component: _0886da31,
    name: "page___en"
  }, {
    path: "/fr/:page?",
    component: _0886da31,
    name: "page___fr"
  }, {
    path: "/hotels/:city",
    component: _498082ca,
    name: "hotels-city___nl"
  }, {
    path: "/inloggen/:token",
    component: _1ab9834a,
    name: "signin-token___nl"
  }, {
    path: "/overnachtingen/:accommodation",
    component: _1b8f2ff8,
    name: "accommodations-accommodation___nl"
  }, {
    path: "/review/:token?",
    component: _acfda458,
    name: "review-token___nl"
  }, {
    path: "/wachtwoord-resetten/:token",
    component: _5d60f39a,
    name: "reset-password-token___nl"
  }, {
    path: "/champagnehuizen/:region/:maison",
    component: _5d35f3ad,
    name: "maisons-region-maison___nl"
  }, {
    path: "/tour/:region?/:maison?/:activity?",
    component: _50fba135,
    name: "tour-region-maison-activity___nl"
  }, {
    path: "/:page?",
    component: _0886da31,
    name: "page___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
