import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=1756809c&scoped=true&"
import script from "./Card.vue?vue&type=script&lang=js&"
export * from "./Card.vue?vue&type=script&lang=js&"
import style0 from "./Card.vue?vue&type=style&index=0&id=1756809c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1756809c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotelStarsDisplay: require('/home/forge/www.champagne-booking.com/components/Hotel/StarsDisplay.vue').default,AppRating: require('/home/forge/www.champagne-booking.com/components/AppRating.vue').default,HotelHighlights: require('/home/forge/www.champagne-booking.com/components/Hotel/Highlights.vue').default})
