
export default {
  layout: 'dashboard',
  data() {
    return {
      opened: false,
      year: new Date().getFullYear(),
      maisonPath: this.localePath({
        name: 'dashboard-maisons',
      }),
      hasMultipleMaisons: false,
    };
  },
  fetchOnServer: false,
  async fetch() {
    const {
      data: { data: slug },
    } = await this.$api('/api/v1/admin/maisons/has-single');

    this.hasMultipleMaisons = !slug;

    if (!!slug) {
      this.maisonPath = this.localePath({
        name: 'dashboard-maisons-maison',
        params: {
          maison: slug,
        },
      });
    }
  },
  mounted() {
    if (!this.$auth.user.is_manager) {
      this.$router.push('/');
    }
  },
  created() {
    if (this.$i18n.locale !== 'fr') {
      this.$router.push(this.switchLocalePath('fr'));
    }
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },
    signout() {
      this.$auth.logout();
    },
  },
  computed: {
    fullname() {
      const { fullname } = this.$auth.user;
      return fullname;
    },
    initials() {
      const { first_name, last_name } = this.$auth.user || {};
      const firstLetter = first_name?.[0] || '-'; 
      const lastLetter = last_name?.[0] || '';
      return `${firstLetter}${lastLetter}`.toUpperCase();
    },
    profile() {
      return this.localePath({
        name: 'dashboard-profile',
      });
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.opened = false;
      },
    },
  },
};
