
export default {
  props: {
    stars: {
      required: true,
      default: 0,
    },
  },
  computed: {
    numericStars() {
      return Number(this.stars) || 0;
    },
  },
};
