import { render, staticRenderFns } from "./Slider.vue?vue&type=template&id=64a6d2cb&scoped=true&"
import script from "./Slider.vue?vue&type=script&lang=js&"
export * from "./Slider.vue?vue&type=script&lang=js&"
import style0 from "./Slider.vue?vue&type=style&index=0&id=64a6d2cb&prod&lang=scss&scoped=true&"
import style1 from "./Slider.vue?vue&type=style&index=1&id=64a6d2cb&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a6d2cb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MaisonCardSkeleton: require('/home/forge/www.champagne-booking.com/components/MaisonCardSkeleton.vue').default,AppLabel: require('/home/forge/www.champagne-booking.com/components/AppLabel.vue').default,AppDistance: require('/home/forge/www.champagne-booking.com/components/AppDistance.vue').default,HotelSliderCard: require('/home/forge/www.champagne-booking.com/components/Hotel/SliderCard.vue').default,AppSwiperItem: require('/home/forge/www.champagne-booking.com/components/AppSwiperItem.vue').default,AppSwiper: require('/home/forge/www.champagne-booking.com/components/AppSwiper.vue').default})
