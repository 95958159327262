import { render, staticRenderFns } from "./Hotels.vue?vue&type=template&id=1f71784a&scoped=true&"
import script from "./Hotels.vue?vue&type=script&lang=js&"
export * from "./Hotels.vue?vue&type=script&lang=js&"
import style0 from "./Hotels.vue?vue&type=style&index=0&id=1f71784a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f71784a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppCkContent: require('/home/forge/www.champagne-booking.com/components/AppCkContent.vue').default,HotelSlider: require('/home/forge/www.champagne-booking.com/components/Hotel/Slider.vue').default,AppLabel: require('/home/forge/www.champagne-booking.com/components/AppLabel.vue').default,HotelCard: require('/home/forge/www.champagne-booking.com/components/Hotel/Card.vue').default,MaisonCardSkeleton: require('/home/forge/www.champagne-booking.com/components/MaisonCardSkeleton.vue').default,BtnUnderline: require('/home/forge/www.champagne-booking.com/components/BtnUnderline.vue').default})
