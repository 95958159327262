import { render, staticRenderFns } from "./SliderCard.vue?vue&type=template&id=302ad3aa&scoped=true&"
import script from "./SliderCard.vue?vue&type=script&lang=js&"
export * from "./SliderCard.vue?vue&type=script&lang=js&"
import style0 from "./SliderCard.vue?vue&type=style&index=0&id=302ad3aa&prod&lang=scss&scoped=true&"
import style1 from "./SliderCard.vue?vue&type=style&index=1&id=302ad3aa&prod&lang=scss&scoped=true&"
import style2 from "./SliderCard.vue?vue&type=style&index=2&id=302ad3aa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "302ad3aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppRating: require('/home/forge/www.champagne-booking.com/components/AppRating.vue').default,HotelStarsDisplay: require('/home/forge/www.champagne-booking.com/components/Hotel/StarsDisplay.vue').default})
