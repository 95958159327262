
export default {
  name: 'HotelCard',
  props: {
    hotel: Object,
    maison: {
      type: Object,
      default: () => ({ name: 'Maison' }),
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        spaceBetween: 10,
        rewind: true,
      },
    };
  },
  methods: {
    getTranslatedText(text) {
      // Parse the nested JSON string
      if (typeof text === 'string') {
        try {
          text = JSON.parse(text);
        } catch (e) {
          console.error('Error parsing text JSON:', e);
          return 'No translation available';
        }
      }

      // Attempt to get the text for the current locale, fallback to 'en' if not available
      const currentLocale = this.$i18n.locale;
      return text[currentLocale] || text['en'] || 'No translation available';
    },
  },
  computed: {
    isMobileLayout() {
      return this.$breakpoints.sMd;
    },
    isDesktopLayout() {
      return this.$breakpoints.lLg;
    },
  },
};
