
import { mapGetters } from 'vuex';

export default {
  name: 'TheHeaderNav',
  watch: {
    $route() {
      let collapse = this.$refs.collapse;

      if (!!collapse) {
        collapse.classList.remove('show');
      }
    },
  },

  computed: {
    ...mapGetters({
      menus: 'settings/getMenus',
    }),
    items() {
      return this.menus?.header_menu?.items ?? [];
    },
  },
};
