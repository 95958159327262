export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const Turnstile = () => import('../../node_modules/nuxt2-turnstile/lib/components/Turnstile.vue' /* webpackChunkName: "components/turnstile" */).then(c => wrapFunctional(c.default || c))
export const AccommodationCard = () => import('../../components/AccommodationCard.vue' /* webpackChunkName: "components/accommodation-card" */).then(c => wrapFunctional(c.default || c))
export const AccommodationRoomSelection = () => import('../../components/AccommodationRoomSelection.vue' /* webpackChunkName: "components/accommodation-room-selection" */).then(c => wrapFunctional(c.default || c))
export const AccountMenu = () => import('../../components/AccountMenu.vue' /* webpackChunkName: "components/account-menu" */).then(c => wrapFunctional(c.default || c))
export const ActivityCard = () => import('../../components/ActivityCard.vue' /* webpackChunkName: "components/activity-card" */).then(c => wrapFunctional(c.default || c))
export const ActivityCardSkeleton = () => import('../../components/ActivityCardSkeleton.vue' /* webpackChunkName: "components/activity-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ActivityItem = () => import('../../components/ActivityItem.vue' /* webpackChunkName: "components/activity-item" */).then(c => wrapFunctional(c.default || c))
export const ActivityList = () => import('../../components/ActivityList.vue' /* webpackChunkName: "components/activity-list" */).then(c => wrapFunctional(c.default || c))
export const ActivityReviewGrid = () => import('../../components/ActivityReviewGrid.vue' /* webpackChunkName: "components/activity-review-grid" */).then(c => wrapFunctional(c.default || c))
export const ActivityReviewResponsive = () => import('../../components/ActivityReviewResponsive.vue' /* webpackChunkName: "components/activity-review-responsive" */).then(c => wrapFunctional(c.default || c))
export const ActivityReviewSwiper = () => import('../../components/ActivityReviewSwiper.vue' /* webpackChunkName: "components/activity-review-swiper" */).then(c => wrapFunctional(c.default || c))
export const ActivitySwiper = () => import('../../components/ActivitySwiper.vue' /* webpackChunkName: "components/activity-swiper" */).then(c => wrapFunctional(c.default || c))
export const AppAccordion = () => import('../../components/AppAccordion.vue' /* webpackChunkName: "components/app-accordion" */).then(c => wrapFunctional(c.default || c))
export const AppAccordionItem = () => import('../../components/AppAccordionItem.vue' /* webpackChunkName: "components/app-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const AppBadge = () => import('../../components/AppBadge.vue' /* webpackChunkName: "components/app-badge" */).then(c => wrapFunctional(c.default || c))
export const AppBanner = () => import('../../components/AppBanner.vue' /* webpackChunkName: "components/app-banner" */).then(c => wrapFunctional(c.default || c))
export const AppBreadcrumbs = () => import('../../components/AppBreadcrumbs.vue' /* webpackChunkName: "components/app-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppCard = () => import('../../components/AppCard.vue' /* webpackChunkName: "components/app-card" */).then(c => wrapFunctional(c.default || c))
export const AppCkContent = () => import('../../components/AppCkContent.vue' /* webpackChunkName: "components/app-ck-content" */).then(c => wrapFunctional(c.default || c))
export const AppCountry = () => import('../../components/AppCountry.vue' /* webpackChunkName: "components/app-country" */).then(c => wrapFunctional(c.default || c))
export const AppDistance = () => import('../../components/AppDistance.vue' /* webpackChunkName: "components/app-distance" */).then(c => wrapFunctional(c.default || c))
export const AppDropdown = () => import('../../components/AppDropdown.vue' /* webpackChunkName: "components/app-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppFavoriteButton = () => import('../../components/AppFavoriteButton.vue' /* webpackChunkName: "components/app-favorite-button" */).then(c => wrapFunctional(c.default || c))
export const AppHero = () => import('../../components/AppHero.vue' /* webpackChunkName: "components/app-hero" */).then(c => wrapFunctional(c.default || c))
export const AppHeroBar = () => import('../../components/AppHeroBar.vue' /* webpackChunkName: "components/app-hero-bar" */).then(c => wrapFunctional(c.default || c))
export const AppIconButton = () => import('../../components/AppIconButton.vue' /* webpackChunkName: "components/app-icon-button" */).then(c => wrapFunctional(c.default || c))
export const AppLabel = () => import('../../components/AppLabel.vue' /* webpackChunkName: "components/app-label" */).then(c => wrapFunctional(c.default || c))
export const AppLeadingNumber = () => import('../../components/AppLeadingNumber.vue' /* webpackChunkName: "components/app-leading-number" */).then(c => wrapFunctional(c.default || c))
export const AppLine = () => import('../../components/AppLine.vue' /* webpackChunkName: "components/app-line" */).then(c => wrapFunctional(c.default || c))
export const AppMessage = () => import('../../components/AppMessage.vue' /* webpackChunkName: "components/app-message" */).then(c => wrapFunctional(c.default || c))
export const AppMobileBreadcrumbs = () => import('../../components/AppMobileBreadcrumbs.vue' /* webpackChunkName: "components/app-mobile-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppPagination = () => import('../../components/AppPagination.vue' /* webpackChunkName: "components/app-pagination" */).then(c => wrapFunctional(c.default || c))
export const AppPrice = () => import('../../components/AppPrice.vue' /* webpackChunkName: "components/app-price" */).then(c => wrapFunctional(c.default || c))
export const AppPriceBlock = () => import('../../components/AppPriceBlock.vue' /* webpackChunkName: "components/app-price-block" */).then(c => wrapFunctional(c.default || c))
export const AppPromotionBanner = () => import('../../components/AppPromotionBanner.vue' /* webpackChunkName: "components/app-promotion-banner" */).then(c => wrapFunctional(c.default || c))
export const AppRating = () => import('../../components/AppRating.vue' /* webpackChunkName: "components/app-rating" */).then(c => wrapFunctional(c.default || c))
export const AppSocials = () => import('../../components/AppSocials.vue' /* webpackChunkName: "components/app-socials" */).then(c => wrapFunctional(c.default || c))
export const AppStars = () => import('../../components/AppStars.vue' /* webpackChunkName: "components/app-stars" */).then(c => wrapFunctional(c.default || c))
export const AppSteps = () => import('../../components/AppSteps.vue' /* webpackChunkName: "components/app-steps" */).then(c => wrapFunctional(c.default || c))
export const AppSwiper = () => import('../../components/AppSwiper.vue' /* webpackChunkName: "components/app-swiper" */).then(c => wrapFunctional(c.default || c))
export const AppSwiperItem = () => import('../../components/AppSwiperItem.vue' /* webpackChunkName: "components/app-swiper-item" */).then(c => wrapFunctional(c.default || c))
export const AppThumbnailGallery = () => import('../../components/AppThumbnailGallery.vue' /* webpackChunkName: "components/app-thumbnail-gallery" */).then(c => wrapFunctional(c.default || c))
export const AppUsp = () => import('../../components/AppUsp.vue' /* webpackChunkName: "components/app-usp" */).then(c => wrapFunctional(c.default || c))
export const AppUspBlock = () => import('../../components/AppUspBlock.vue' /* webpackChunkName: "components/app-usp-block" */).then(c => wrapFunctional(c.default || c))
export const AppUspBlockSkeleton = () => import('../../components/AppUspBlockSkeleton.vue' /* webpackChunkName: "components/app-usp-block-skeleton" */).then(c => wrapFunctional(c.default || c))
export const AppYoutubePlayer = () => import('../../components/AppYoutubePlayer.vue' /* webpackChunkName: "components/app-youtube-player" */).then(c => wrapFunctional(c.default || c))
export const BModal = () => import('../../components/BModal.vue' /* webpackChunkName: "components/b-modal" */).then(c => wrapFunctional(c.default || c))
export const BPane = () => import('../../components/BPane.vue' /* webpackChunkName: "components/b-pane" */).then(c => wrapFunctional(c.default || c))
export const BTab = () => import('../../components/BTab.vue' /* webpackChunkName: "components/b-tab" */).then(c => wrapFunctional(c.default || c))
export const BTabs = () => import('../../components/BTabs.vue' /* webpackChunkName: "components/b-tabs" */).then(c => wrapFunctional(c.default || c))
export const BookActivitySidebar = () => import('../../components/BookActivitySidebar.vue' /* webpackChunkName: "components/book-activity-sidebar" */).then(c => wrapFunctional(c.default || c))
export const BookConfirmationForm = () => import('../../components/BookConfirmationForm.vue' /* webpackChunkName: "components/book-confirmation-form" */).then(c => wrapFunctional(c.default || c))
export const BookConfirmationOverview = () => import('../../components/BookConfirmationOverview.vue' /* webpackChunkName: "components/book-confirmation-overview" */).then(c => wrapFunctional(c.default || c))
export const BookContactBox = () => import('../../components/BookContactBox.vue' /* webpackChunkName: "components/book-contact-box" */).then(c => wrapFunctional(c.default || c))
export const BookForm = () => import('../../components/BookForm.vue' /* webpackChunkName: "components/book-form" */).then(c => wrapFunctional(c.default || c))
export const BookStripeBox = () => import('../../components/BookStripeBox.vue' /* webpackChunkName: "components/book-stripe-box" */).then(c => wrapFunctional(c.default || c))
export const BookWrapper = () => import('../../components/BookWrapper.vue' /* webpackChunkName: "components/book-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Booking = () => import('../../components/Booking.vue' /* webpackChunkName: "components/booking" */).then(c => wrapFunctional(c.default || c))
export const BookingStatusBadge = () => import('../../components/BookingStatusBadge.vue' /* webpackChunkName: "components/booking-status-badge" */).then(c => wrapFunctional(c.default || c))
export const BookingsDatePicker = () => import('../../components/BookingsDatePicker.vue' /* webpackChunkName: "components/bookings-date-picker" */).then(c => wrapFunctional(c.default || c))
export const BookingsDatePickerInline = () => import('../../components/BookingsDatePickerInline.vue' /* webpackChunkName: "components/bookings-date-picker-inline" */).then(c => wrapFunctional(c.default || c))
export const BookingsTimePicker = () => import('../../components/BookingsTimePicker.vue' /* webpackChunkName: "components/bookings-time-picker" */).then(c => wrapFunctional(c.default || c))
export const BookingsTimePickerSingleTime = () => import('../../components/BookingsTimePickerSingleTime.vue' /* webpackChunkName: "components/bookings-time-picker-single-time" */).then(c => wrapFunctional(c.default || c))
export const BtnUnderline = () => import('../../components/BtnUnderline.vue' /* webpackChunkName: "components/btn-underline" */).then(c => wrapFunctional(c.default || c))
export const ChartBar = () => import('../../components/ChartBar.vue' /* webpackChunkName: "components/chart-bar" */).then(c => wrapFunctional(c.default || c))
export const ChartLine = () => import('../../components/ChartLine.vue' /* webpackChunkName: "components/chart-line" */).then(c => wrapFunctional(c.default || c))
export const ChartPie = () => import('../../components/ChartPie.vue' /* webpackChunkName: "components/chart-pie" */).then(c => wrapFunctional(c.default || c))
export const ChatConversation = () => import('../../components/ChatConversation.vue' /* webpackChunkName: "components/chat-conversation" */).then(c => wrapFunctional(c.default || c))
export const ChatMessage = () => import('../../components/ChatMessage.vue' /* webpackChunkName: "components/chat-message" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const CustomTurnstile = () => import('../../components/CustomTurnstile.vue' /* webpackChunkName: "components/custom-turnstile" */).then(c => wrapFunctional(c.default || c))
export const DashboardActivityAvailabilityTable = () => import('../../components/DashboardActivityAvailabilityTable.vue' /* webpackChunkName: "components/dashboard-activity-availability-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardAgenda = () => import('../../components/DashboardAgenda.vue' /* webpackChunkName: "components/dashboard-agenda" */).then(c => wrapFunctional(c.default || c))
export const DashboardAgendaBulk = () => import('../../components/DashboardAgendaBulk.vue' /* webpackChunkName: "components/dashboard-agenda-bulk" */).then(c => wrapFunctional(c.default || c))
export const DashboardAgendas = () => import('../../components/DashboardAgendas.vue' /* webpackChunkName: "components/dashboard-agendas" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingCard = () => import('../../components/DashboardBookingCard.vue' /* webpackChunkName: "components/dashboard-booking-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingChart = () => import('../../components/DashboardBookingChart.vue' /* webpackChunkName: "components/dashboard-booking-chart" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingSwiper = () => import('../../components/DashboardBookingSwiper.vue' /* webpackChunkName: "components/dashboard-booking-swiper" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingsCalendar = () => import('../../components/DashboardBookingsCalendar.vue' /* webpackChunkName: "components/dashboard-bookings-calendar" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingsCalendarAccordionItem = () => import('../../components/DashboardBookingsCalendarAccordionItem.vue' /* webpackChunkName: "components/dashboard-bookings-calendar-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingsCalendarDate = () => import('../../components/DashboardBookingsCalendarDate.vue' /* webpackChunkName: "components/dashboard-bookings-calendar-date" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingsCalendarItem = () => import('../../components/DashboardBookingsCalendarItem.vue' /* webpackChunkName: "components/dashboard-bookings-calendar-item" */).then(c => wrapFunctional(c.default || c))
export const DashboardChat = () => import('../../components/DashboardChat.vue' /* webpackChunkName: "components/dashboard-chat" */).then(c => wrapFunctional(c.default || c))
export const DashboardCloseAccommodation = () => import('../../components/DashboardCloseAccommodation.vue' /* webpackChunkName: "components/dashboard-close-accommodation" */).then(c => wrapFunctional(c.default || c))
export const DashboardCloseMaison = () => import('../../components/DashboardCloseMaison.vue' /* webpackChunkName: "components/dashboard-close-maison" */).then(c => wrapFunctional(c.default || c))
export const DashboardClosedDaysList = () => import('../../components/DashboardClosedDaysList.vue' /* webpackChunkName: "components/dashboard-closed-days-list" */).then(c => wrapFunctional(c.default || c))
export const DashboardClosedDaysRow = () => import('../../components/DashboardClosedDaysRow.vue' /* webpackChunkName: "components/dashboard-closed-days-row" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeader = () => import('../../components/DashboardHeader.vue' /* webpackChunkName: "components/dashboard-header" */).then(c => wrapFunctional(c.default || c))
export const DashboardMaisonActivitiesCalendar = () => import('../../components/DashboardMaisonActivitiesCalendar.vue' /* webpackChunkName: "components/dashboard-maison-activities-calendar" */).then(c => wrapFunctional(c.default || c))
export const DashboardMaisonActivitiesCalendarDate = () => import('../../components/DashboardMaisonActivitiesCalendarDate.vue' /* webpackChunkName: "components/dashboard-maison-activities-calendar-date" */).then(c => wrapFunctional(c.default || c))
export const DashboardMaisonActivitiesCalendarDateSlot = () => import('../../components/DashboardMaisonActivitiesCalendarDateSlot.vue' /* webpackChunkName: "components/dashboard-maison-activities-calendar-date-slot" */).then(c => wrapFunctional(c.default || c))
export const DashboardMaisonActivitiesCalendarDateSlotToBeAdded = () => import('../../components/DashboardMaisonActivitiesCalendarDateSlotToBeAdded.vue' /* webpackChunkName: "components/dashboard-maison-activities-calendar-date-slot-to-be-added" */).then(c => wrapFunctional(c.default || c))
export const DashboardMaisonActivitiesCalendarItem = () => import('../../components/DashboardMaisonActivitiesCalendarItem.vue' /* webpackChunkName: "components/dashboard-maison-activities-calendar-item" */).then(c => wrapFunctional(c.default || c))
export const DashboardMaisonFilter = () => import('../../components/DashboardMaisonFilter.vue' /* webpackChunkName: "components/dashboard-maison-filter" */).then(c => wrapFunctional(c.default || c))
export const DashboardNavigation = () => import('../../components/DashboardNavigation.vue' /* webpackChunkName: "components/dashboard-navigation" */).then(c => wrapFunctional(c.default || c))
export const DashboardReviewResponseForm = () => import('../../components/DashboardReviewResponseForm.vue' /* webpackChunkName: "components/dashboard-review-response-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardReviewsChart = () => import('../../components/DashboardReviewsChart.vue' /* webpackChunkName: "components/dashboard-reviews-chart" */).then(c => wrapFunctional(c.default || c))
export const DashboardStatistics = () => import('../../components/DashboardStatistics.vue' /* webpackChunkName: "components/dashboard-statistics" */).then(c => wrapFunctional(c.default || c))
export const DashboardWrapper = () => import('../../components/DashboardWrapper.vue' /* webpackChunkName: "components/dashboard-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FavoriteItem = () => import('../../components/FavoriteItem.vue' /* webpackChunkName: "components/favorite-item" */).then(c => wrapFunctional(c.default || c))
export const FavoriteItemSkeleton = () => import('../../components/FavoriteItemSkeleton.vue' /* webpackChunkName: "components/favorite-item-skeleton" */).then(c => wrapFunctional(c.default || c))
export const FavoriteList = () => import('../../components/FavoriteList.vue' /* webpackChunkName: "components/favorite-list" */).then(c => wrapFunctional(c.default || c))
export const ForgotPasswordForm = () => import('../../components/ForgotPasswordForm.vue' /* webpackChunkName: "components/forgot-password-form" */).then(c => wrapFunctional(c.default || c))
export const FormBookActivity = () => import('../../components/FormBookActivity.vue' /* webpackChunkName: "components/form-book-activity" */).then(c => wrapFunctional(c.default || c))
export const FormNewsletter = () => import('../../components/FormNewsletter.vue' /* webpackChunkName: "components/form-newsletter" */).then(c => wrapFunctional(c.default || c))
export const FormPassword = () => import('../../components/FormPassword.vue' /* webpackChunkName: "components/form-password" */).then(c => wrapFunctional(c.default || c))
export const FormProfile = () => import('../../components/FormProfile.vue' /* webpackChunkName: "components/form-profile" */).then(c => wrapFunctional(c.default || c))
export const FormulateCompleteDatePicker = () => import('../../components/FormulateCompleteDatePicker.vue' /* webpackChunkName: "components/formulate-complete-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FormulateCompleteDatePickerHeader = () => import('../../components/FormulateCompleteDatePickerHeader.vue' /* webpackChunkName: "components/formulate-complete-date-picker-header" */).then(c => wrapFunctional(c.default || c))
export const FormulateCompleteDatePickerSingleDate = () => import('../../components/FormulateCompleteDatePickerSingleDate.vue' /* webpackChunkName: "components/formulate-complete-date-picker-single-date" */).then(c => wrapFunctional(c.default || c))
export const FormulateCountrySelect = () => import('../../components/FormulateCountrySelect.vue' /* webpackChunkName: "components/formulate-country-select" */).then(c => wrapFunctional(c.default || c))
export const FormulateDatePicker = () => import('../../components/FormulateDatePicker.vue' /* webpackChunkName: "components/formulate-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FormulateDateRangePicker = () => import('../../components/FormulateDateRangePicker.vue' /* webpackChunkName: "components/formulate-date-range-picker" */).then(c => wrapFunctional(c.default || c))
export const FormulateGeoSearch = () => import('../../components/FormulateGeoSearch.vue' /* webpackChunkName: "components/formulate-geo-search" */).then(c => wrapFunctional(c.default || c))
export const FormulatePhoneInput = () => import('../../components/FormulatePhoneInput.vue' /* webpackChunkName: "components/formulate-phone-input" */).then(c => wrapFunctional(c.default || c))
export const FormulateQuantity = () => import('../../components/FormulateQuantity.vue' /* webpackChunkName: "components/formulate-quantity" */).then(c => wrapFunctional(c.default || c))
export const FormulateVSelect = () => import('../../components/FormulateVSelect.vue' /* webpackChunkName: "components/formulate-v-select" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapsAnchor = () => import('../../components/GoogleMapsAnchor.vue' /* webpackChunkName: "components/google-maps-anchor" */).then(c => wrapFunctional(c.default || c))
export const LanguageFlag = () => import('../../components/LanguageFlag.vue' /* webpackChunkName: "components/language-flag" */).then(c => wrapFunctional(c.default || c))
export const LanguageSelect = () => import('../../components/LanguageSelect.vue' /* webpackChunkName: "components/language-select" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const LaravelDataTable = () => import('../../components/LaravelDataTable.vue' /* webpackChunkName: "components/laravel-data-table" */).then(c => wrapFunctional(c.default || c))
export const LaravelGrid = () => import('../../components/LaravelGrid.vue' /* webpackChunkName: "components/laravel-grid" */).then(c => wrapFunctional(c.default || c))
export const MaisonCard = () => import('../../components/MaisonCard.vue' /* webpackChunkName: "components/maison-card" */).then(c => wrapFunctional(c.default || c))
export const MaisonCardSkeleton = () => import('../../components/MaisonCardSkeleton.vue' /* webpackChunkName: "components/maison-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MaisonCloseByContainer = () => import('../../components/MaisonCloseByContainer.vue' /* webpackChunkName: "components/maison-close-by-container" */).then(c => wrapFunctional(c.default || c))
export const MaisonCloseBySlider = () => import('../../components/MaisonCloseBySlider.vue' /* webpackChunkName: "components/maison-close-by-slider" */).then(c => wrapFunctional(c.default || c))
export const MaisonFaq = () => import('../../components/MaisonFaq.vue' /* webpackChunkName: "components/maison-faq" */).then(c => wrapFunctional(c.default || c))
export const MaisonFaqItem = () => import('../../components/MaisonFaqItem.vue' /* webpackChunkName: "components/maison-faq-item" */).then(c => wrapFunctional(c.default || c))
export const MaisonFilters = () => import('../../components/MaisonFilters.vue' /* webpackChunkName: "components/maison-filters" */).then(c => wrapFunctional(c.default || c))
export const MaisonGallery = () => import('../../components/MaisonGallery.vue' /* webpackChunkName: "components/maison-gallery" */).then(c => wrapFunctional(c.default || c))
export const MaisonGrid = () => import('../../components/MaisonGrid.vue' /* webpackChunkName: "components/maison-grid" */).then(c => wrapFunctional(c.default || c))
export const MaisonMarkerPopup = () => import('../../components/MaisonMarkerPopup.vue' /* webpackChunkName: "components/maison-marker-popup" */).then(c => wrapFunctional(c.default || c))
export const MaisonMarkerPopupSmall = () => import('../../components/MaisonMarkerPopupSmall.vue' /* webpackChunkName: "components/maison-marker-popup-small" */).then(c => wrapFunctional(c.default || c))
export const MaisonSidebar = () => import('../../components/MaisonSidebar.vue' /* webpackChunkName: "components/maison-sidebar" */).then(c => wrapFunctional(c.default || c))
export const MaisonSidebarList = () => import('../../components/MaisonSidebarList.vue' /* webpackChunkName: "components/maison-sidebar-list" */).then(c => wrapFunctional(c.default || c))
export const MaisonSidebarListItem = () => import('../../components/MaisonSidebarListItem.vue' /* webpackChunkName: "components/maison-sidebar-list-item" */).then(c => wrapFunctional(c.default || c))
export const MaisonSingleMap = () => import('../../components/MaisonSingleMap.vue' /* webpackChunkName: "components/maison-single-map" */).then(c => wrapFunctional(c.default || c))
export const MaisonSingleMapModal = () => import('../../components/MaisonSingleMapModal.vue' /* webpackChunkName: "components/maison-single-map-modal" */).then(c => wrapFunctional(c.default || c))
export const MaisonSlider = () => import('../../components/MaisonSlider.vue' /* webpackChunkName: "components/maison-slider" */).then(c => wrapFunctional(c.default || c))
export const MaisonSorting = () => import('../../components/MaisonSorting.vue' /* webpackChunkName: "components/maison-sorting" */).then(c => wrapFunctional(c.default || c))
export const MapBoxMap = () => import('../../components/MapBoxMap.vue' /* webpackChunkName: "components/map-box-map" */).then(c => wrapFunctional(c.default || c))
export const MapBoxMarker = () => import('../../components/MapBoxMarker.vue' /* webpackChunkName: "components/map-box-marker" */).then(c => wrapFunctional(c.default || c))
export const OverviewSteps = () => import('../../components/OverviewSteps.vue' /* webpackChunkName: "components/overview-steps" */).then(c => wrapFunctional(c.default || c))
export const PostItem = () => import('../../components/PostItem.vue' /* webpackChunkName: "components/post-item" */).then(c => wrapFunctional(c.default || c))
export const PropertyHeader = () => import('../../components/PropertyHeader.vue' /* webpackChunkName: "components/property-header" */).then(c => wrapFunctional(c.default || c))
export const PropertyTabs = () => import('../../components/PropertyTabs.vue' /* webpackChunkName: "components/property-tabs" */).then(c => wrapFunctional(c.default || c))
export const RegionCard = () => import('../../components/RegionCard.vue' /* webpackChunkName: "components/region-card" */).then(c => wrapFunctional(c.default || c))
export const RegionCardSkeleton = () => import('../../components/RegionCardSkeleton.vue' /* webpackChunkName: "components/region-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const RegionSwiper = () => import('../../components/RegionSwiper.vue' /* webpackChunkName: "components/region-swiper" */).then(c => wrapFunctional(c.default || c))
export const ResetPasswordForm = () => import('../../components/ResetPasswordForm.vue' /* webpackChunkName: "components/reset-password-form" */).then(c => wrapFunctional(c.default || c))
export const ReviewBlock = () => import('../../components/ReviewBlock.vue' /* webpackChunkName: "components/review-block" */).then(c => wrapFunctional(c.default || c))
export const ReviewForm = () => import('../../components/ReviewForm.vue' /* webpackChunkName: "components/review-form" */).then(c => wrapFunctional(c.default || c))
export const ReviewItem = () => import('../../components/ReviewItem.vue' /* webpackChunkName: "components/review-item" */).then(c => wrapFunctional(c.default || c))
export const ReviewResponse = () => import('../../components/ReviewResponse.vue' /* webpackChunkName: "components/review-response" */).then(c => wrapFunctional(c.default || c))
export const ReviewResponses = () => import('../../components/ReviewResponses.vue' /* webpackChunkName: "components/review-responses" */).then(c => wrapFunctional(c.default || c))
export const ReviewSlider = () => import('../../components/ReviewSlider.vue' /* webpackChunkName: "components/review-slider" */).then(c => wrapFunctional(c.default || c))
export const ReviewSliderItem = () => import('../../components/ReviewSliderItem.vue' /* webpackChunkName: "components/review-slider-item" */).then(c => wrapFunctional(c.default || c))
export const RoomCard = () => import('../../components/RoomCard.vue' /* webpackChunkName: "components/room-card" */).then(c => wrapFunctional(c.default || c))
export const RoomCardSkeleton = () => import('../../components/RoomCardSkeleton.vue' /* webpackChunkName: "components/room-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const RoomGrid = () => import('../../components/RoomGrid.vue' /* webpackChunkName: "components/room-grid" */).then(c => wrapFunctional(c.default || c))
export const RoomHighlight = () => import('../../components/RoomHighlight.vue' /* webpackChunkName: "components/room-highlight" */).then(c => wrapFunctional(c.default || c))
export const RoomHighlights = () => import('../../components/RoomHighlights.vue' /* webpackChunkName: "components/room-highlights" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchButton = () => import('../../components/SearchButton.vue' /* webpackChunkName: "components/search-button" */).then(c => wrapFunctional(c.default || c))
export const SearchHotels = () => import('../../components/SearchHotels.vue' /* webpackChunkName: "components/search-hotels" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const SearchMaisonsAndActivities = () => import('../../components/SearchMaisonsAndActivities.vue' /* webpackChunkName: "components/search-maisons-and-activities" */).then(c => wrapFunctional(c.default || c))
export const SearchModal = () => import('../../components/SearchModal.vue' /* webpackChunkName: "components/search-modal" */).then(c => wrapFunctional(c.default || c))
export const SearchModalAccommodation = () => import('../../components/SearchModalAccommodation.vue' /* webpackChunkName: "components/search-modal-accommodation" */).then(c => wrapFunctional(c.default || c))
export const SearchModalActivity = () => import('../../components/SearchModalActivity.vue' /* webpackChunkName: "components/search-modal-activity" */).then(c => wrapFunctional(c.default || c))
export const SearchModalMaison = () => import('../../components/SearchModalMaison.vue' /* webpackChunkName: "components/search-modal-maison" */).then(c => wrapFunctional(c.default || c))
export const SearchModalPage = () => import('../../components/SearchModalPage.vue' /* webpackChunkName: "components/search-modal-page" */).then(c => wrapFunctional(c.default || c))
export const SearchModalPost = () => import('../../components/SearchModalPost.vue' /* webpackChunkName: "components/search-modal-post" */).then(c => wrapFunctional(c.default || c))
export const SearchModalRoom = () => import('../../components/SearchModalRoom.vue' /* webpackChunkName: "components/search-modal-room" */).then(c => wrapFunctional(c.default || c))
export const SearchModalSidebar = () => import('../../components/SearchModalSidebar.vue' /* webpackChunkName: "components/search-modal-sidebar" */).then(c => wrapFunctional(c.default || c))
export const SearchModalSidebarAccommodation = () => import('../../components/SearchModalSidebarAccommodation.vue' /* webpackChunkName: "components/search-modal-sidebar-accommodation" */).then(c => wrapFunctional(c.default || c))
export const SearchModalSidebarActivity = () => import('../../components/SearchModalSidebarActivity.vue' /* webpackChunkName: "components/search-modal-sidebar-activity" */).then(c => wrapFunctional(c.default || c))
export const SearchModalSidebarMaison = () => import('../../components/SearchModalSidebarMaison.vue' /* webpackChunkName: "components/search-modal-sidebar-maison" */).then(c => wrapFunctional(c.default || c))
export const SearchModalSidebarPage = () => import('../../components/SearchModalSidebarPage.vue' /* webpackChunkName: "components/search-modal-sidebar-page" */).then(c => wrapFunctional(c.default || c))
export const SearchModalSidebarPost = () => import('../../components/SearchModalSidebarPost.vue' /* webpackChunkName: "components/search-modal-sidebar-post" */).then(c => wrapFunctional(c.default || c))
export const SearchModalSidebarRoom = () => import('../../components/SearchModalSidebarRoom.vue' /* webpackChunkName: "components/search-modal-sidebar-room" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../components/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const SearchTabs = () => import('../../components/SearchTabs.vue' /* webpackChunkName: "components/search-tabs" */).then(c => wrapFunctional(c.default || c))
export const SigninForm = () => import('../../components/SigninForm.vue' /* webpackChunkName: "components/signin-form" */).then(c => wrapFunctional(c.default || c))
export const SignupForm = () => import('../../components/SignupForm.vue' /* webpackChunkName: "components/signup-form" */).then(c => wrapFunctional(c.default || c))
export const SignupMaisonForm = () => import('../../components/SignupMaisonForm.vue' /* webpackChunkName: "components/signup-maison-form" */).then(c => wrapFunctional(c.default || c))
export const Skeleton = () => import('../../components/Skeleton.vue' /* webpackChunkName: "components/skeleton" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheFooterNav = () => import('../../components/TheFooterNav.vue' /* webpackChunkName: "components/the-footer-nav" */).then(c => wrapFunctional(c.default || c))
export const TheFooterNavItem = () => import('../../components/TheFooterNavItem.vue' /* webpackChunkName: "components/the-footer-nav-item" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderNav = () => import('../../components/TheHeaderNav.vue' /* webpackChunkName: "components/the-header-nav" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderNavItem = () => import('../../components/TheHeaderNavItem.vue' /* webpackChunkName: "components/the-header-nav-item" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderSubmenuButton = () => import('../../components/TheHeaderSubmenuButton.vue' /* webpackChunkName: "components/the-header-submenu-button" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderSubmenuItem = () => import('../../components/TheHeaderSubmenuItem.vue' /* webpackChunkName: "components/the-header-submenu-item" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderSubmenuLabel = () => import('../../components/TheHeaderSubmenuLabel.vue' /* webpackChunkName: "components/the-header-submenu-label" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderUserMenu = () => import('../../components/TheHeaderUserMenu.vue' /* webpackChunkName: "components/the-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const TheLayout = () => import('../../components/TheLayout.vue' /* webpackChunkName: "components/the-layout" */).then(c => wrapFunctional(c.default || c))
export const UspCard = () => import('../../components/UspCard.vue' /* webpackChunkName: "components/usp-card" */).then(c => wrapFunctional(c.default || c))
export const UspItem = () => import('../../components/UspItem.vue' /* webpackChunkName: "components/usp-item" */).then(c => wrapFunctional(c.default || c))
export const UspList = () => import('../../components/UspList.vue' /* webpackChunkName: "components/usp-list" */).then(c => wrapFunctional(c.default || c))
export const CoreLazyLoadGrid = () => import('../../components/Core/LazyLoadGrid.vue' /* webpackChunkName: "components/core-lazy-load-grid" */).then(c => wrapFunctional(c.default || c))
export const HotelCard = () => import('../../components/Hotel/Card.vue' /* webpackChunkName: "components/hotel-card" */).then(c => wrapFunctional(c.default || c))
export const HotelGrid = () => import('../../components/Hotel/Grid.vue' /* webpackChunkName: "components/hotel-grid" */).then(c => wrapFunctional(c.default || c))
export const HotelHighlights = () => import('../../components/Hotel/Highlights.vue' /* webpackChunkName: "components/hotel-highlights" */).then(c => wrapFunctional(c.default || c))
export const HotelMapWidget = () => import('../../components/Hotel/MapWidget.vue' /* webpackChunkName: "components/hotel-map-widget" */).then(c => wrapFunctional(c.default || c))
export const HotelMarkerPopup = () => import('../../components/Hotel/MarkerPopup.vue' /* webpackChunkName: "components/hotel-marker-popup" */).then(c => wrapFunctional(c.default || c))
export const HotelSearch = () => import('../../components/Hotel/Search.vue' /* webpackChunkName: "components/hotel-search" */).then(c => wrapFunctional(c.default || c))
export const HotelSlider = () => import('../../components/Hotel/Slider.vue' /* webpackChunkName: "components/hotel-slider" */).then(c => wrapFunctional(c.default || c))
export const HotelSliderCard = () => import('../../components/Hotel/SliderCard.vue' /* webpackChunkName: "components/hotel-slider-card" */).then(c => wrapFunctional(c.default || c))
export const HotelSliderContainer = () => import('../../components/Hotel/SliderContainer.vue' /* webpackChunkName: "components/hotel-slider-container" */).then(c => wrapFunctional(c.default || c))
export const HotelStarsDisplay = () => import('../../components/Hotel/StarsDisplay.vue' /* webpackChunkName: "components/hotel-stars-display" */).then(c => wrapFunctional(c.default || c))
export const HouseCard = () => import('../../components/House/Card.vue' /* webpackChunkName: "components/house-card" */).then(c => wrapFunctional(c.default || c))
export const HouseGrid = () => import('../../components/House/Grid.vue' /* webpackChunkName: "components/house-grid" */).then(c => wrapFunctional(c.default || c))
export const ModalAllReviews = () => import('../../components/Modal/AllReviews.vue' /* webpackChunkName: "components/modal-all-reviews" */).then(c => wrapFunctional(c.default || c))
export const PageAlert = () => import('../../components/Page/Alert.vue' /* webpackChunkName: "components/page-alert" */).then(c => wrapFunctional(c.default || c))
export const PageBanner = () => import('../../components/Page/Banner.vue' /* webpackChunkName: "components/page-banner" */).then(c => wrapFunctional(c.default || c))
export const PageComponentRenderer = () => import('../../components/Page/ComponentRenderer.vue' /* webpackChunkName: "components/page-component-renderer" */).then(c => wrapFunctional(c.default || c))
export const PageDoubleGrid = () => import('../../components/Page/DoubleGrid.vue' /* webpackChunkName: "components/page-double-grid" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/Page/Header.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageHotels = () => import('../../components/Page/Hotels.vue' /* webpackChunkName: "components/page-hotels" */).then(c => wrapFunctional(c.default || c))
export const PageMaisons = () => import('../../components/Page/Maisons.vue' /* webpackChunkName: "components/page-maisons" */).then(c => wrapFunctional(c.default || c))
export const PageNewsletter = () => import('../../components/Page/Newsletter.vue' /* webpackChunkName: "components/page-newsletter" */).then(c => wrapFunctional(c.default || c))
export const PageRegions = () => import('../../components/Page/Regions.vue' /* webpackChunkName: "components/page-regions" */).then(c => wrapFunctional(c.default || c))
export const PageReviewSlider = () => import('../../components/Page/ReviewSlider.vue' /* webpackChunkName: "components/page-review-slider" */).then(c => wrapFunctional(c.default || c))
export const PageSearchHero = () => import('../../components/Page/SearchHero.vue' /* webpackChunkName: "components/page-search-hero" */).then(c => wrapFunctional(c.default || c))
export const PageText = () => import('../../components/Page/Text.vue' /* webpackChunkName: "components/page-text" */).then(c => wrapFunctional(c.default || c))
export const PageUsps = () => import('../../components/Page/Usps.vue' /* webpackChunkName: "components/page-usps" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
